import HttpClient from "./api";

class AffiliateApi extends HttpClient {
    public constructor() {
        super();
    }

    getManagersContact() {
        return this.doGet('api/v3/user/manager/contact');
    }
}

export const affiliateApi = new AffiliateApi();