import Title from "../../components/Title/Title";
import {Col, Container, Row, Table} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Route, Switch } from "react-router";
import { UserSettings } from "../../components/UserSettings";
import { HistoricPayments } from "../../components/HistoricPayments";
import { PaymentSettings } from "../../components/PaymentSettings";
import "./Profile.scss";
import PasswordReset from "../../components/PasswordReset/PasswordReset";
import {useEffect, useState} from "react";
import {affiliateApi} from "../../api/affiliateApi";
import {
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";

const Profile = () => {
  const [contact, setContact] = useState();

  useEffect(() => {
    affiliateApi.getManagersContact().then(c => setContact(c))
  }, []);

  return (
    <>
      <Title title="Profile info" />
      <Container>
        <Row>
          <Col lg="4">
            <div className="data__wrapper">
              <NavLink
                to="/admin/profile/user-settings"
                activeClassName="active"
                className="toggleSettings"
              >
                User Settings
              </NavLink>
              <NavLink
                to="/admin/profile/payment-settings"
                activeClassName="active"
                className="toggleSettings"
              >
                Payment Settings
              </NavLink>
              <NavLink
                to="/admin/profile/historic-payments"
                activeClassName="active"
                className="toggleSettings"
              >
                Historic Payments
              </NavLink>
              <NavLink
                to="/admin/profile/password-reset"
                activeClassName="active"
                className="toggleSettings"
              >
                Change Password
              </NavLink>
            </div>
            <div className="data__wrapper">
              <center><h4>Your affiliate Manager</h4></center>
              <div className="profile">
                <hr />
                <div className="profile__descr">
                  <a href="mailto:affiliates@winningpartner.com">
                    <p className="profile__text">
                      { contact && contact.email }
                    </p>
                  </a>
                  { contact && contact.contacts
                      .map((c) => <Grid
                          item
                          container
                          xs={12}
                          direction="row"
                          justify="center"
                          alignItems="center"
                      >
                        <label htmlFor="firstName">{ c.platform }</label>
                        <TextField  disabled defaultValue={ c.contact }/>
                      </Grid>)
                  }
                </div>
              </div>
            </div>
          </Col>
          <Col lg="8">
            <div className="data__wrapper">
              <Switch>
                <Route path="/admin/profile/user-settings" exact component={UserSettings} />
                <Route path="/admin/profile/payment-settings" exact component={PaymentSettings} />
                <Route path="/admin/profile/historic-payments" exact component={HistoricPayments} />
                <Route path="/admin/profile/password-reset" exact component={PasswordReset} />
              </Switch>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
